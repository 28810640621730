import React, { useState } from "react";
import { Fade } from "react-awesome-reveal";
import "./Analytics.css";
import Image1 from "../../../../images/Innovation/Analytics/image1.webp";
import Image2 from "../../../../images/Innovation/Analytics/image2.webp";
import Image3 from "../../../../images/Innovation/Analytics/image3.webp";
import Image4 from "../../../../images/Innovation/Analytics/Image4.webp";

export default function Analytics() {
  const [activeTab, setTab] = useState("Consult");
  const tabs = [
    {
      tab: "Consult",
      title1: "Data Migration Advisory",
      title2: "Data Modernization Advisory",
      title3: "Customized Smart Analytics & BI Strategies",
      description1:
        "Root cause analysis of gaps within current infrastructural framework. Advice on incorporation of tools to optimize data management structure.",
      description2:
        "Evaluate options to enhance current data infrastructure. Explore streamlining strategies. Monitor & secure data against theft & establish recovery mechanisms.",
      description3:
        "Develop pipelines to consolidate data in real time from source to target repositories. Determine most effective Data Visualization Tools to extract insights.",
      imageUrl:
        "https://abacuscambridge.com/hs-fs/hubfs/COnsult.jpg?width=900&height=671&name=COnsult.jpg",
    },
    {
      tab: "Implement",
      title1: "Database Migration",
      title2: "Data Warehouse",
      title3: "BI Dashboards & Visualizations",
      description1:
        "Convert your vision into an Actionable Data Platform with our extensive technical experience in developing solutions on GCP",
      description2:
        "Maximize data processing by migrating or constructing data repositories on top of scalable, fully managed, data warehouses e.g., BigQuery or BigQuery Omni (multi-cloud).",
      description3:
        "Incorporate interactive dashboards & reports to assess KPIs from your data. Provide specialized help to maximize usage of BI & Visualization tool, Looker.",
      imageUrl:
        "https://abacuscambridge.com/hs-fs/hubfs/Implement.jpg?width=900&height=671&name=Implement.jpg",
    },
    {
      tab: "Manage",
      title1: "Data Environment Assessments",
      title2: "24/7 Support Services",
      title3: "Governance & Reporting",
      description1:
        "Assess & manage security architecture, database server-level configurations, and encryption-related, RPO/RTO, availability, redundancy & other diagnostic data requirements.",
      description2:
        "Expert Data Specialists available to provide customized input based on your app use case. Proactive maintenance, repair of issues, consulting, or hands-on support available.",
      description3:
        "Insight into your data management environment via regular analytics and dashboard visualization. Regular review sessions to facilitate improvements and address gaps.",
      imageUrl:
        "https://abacuscambridge.com/hs-fs/hubfs/Manage.jpg?width=900&height=671&name=Manage.jpg",
    },
    {
      tab: "Improve",
      title1: "Smart Analytics",
      title2: "Machine Learning Enablement",
      title3: "Architecture & Cost",
      description1:
        "Assistance in ingesting, processing, and analyzing real-time event streams, enabling impactful decision-making based on high-value, time-sensitive insights.",
      description2:
        "Our ML Specialists create recommendation engines for you, letting your data drive your strategy. Provide custom-built models for analyzing time-to-market, churn rates, consumer payment patterns etc.",
      description3:
        "Optimizations Ensure optimization of your architecture & cloud data spending. Create a billing roadmap based on business growth & budget requirements.",
      imageUrl:
        "https://abacuscambridge.com/hs-fs/hubfs/Improve.jpg?width=900&height=671&name=Improve.jpg",
    },
  ];

  const Filtered = tabs.filter((each) => each.tab === activeTab);
  const cards = [
    {
      imageUrl: Image1,
      title: "BigQuery",
      description:
        "A serverless data warehouse fully managed by Abacus Cambridge Partners that enables downtime-free upgrades and maintenance, and effortless scaling. Analyze petabytes of data at high velocity and with zero operational overhead. Enhance organizational agility and provide time-sensitive, high-value insights in minimal time with maximal security",
    },
    {
      imageUrl: Image2,
      title: "Looker",
      description:
        "Empower your business with a versatile business intelligence solution that simplifies data description and metric definition, enabling all teams to explore and visualize data insights.",
    },
  ];
  return (
    <div className="AnalyticsTotalContainer">
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-12">
            <div>
              <div className="AnalyticsImageContainer  mr-0 w-100"></div>

              <div className="content-container">
                <Fade direction="up">
                  <h1 className="p-2 mb-3 AnalyticsHeading">
                    Analytics and BI Services <br /> Transform Data into
                    Actionable Insights
                  </h1>
                </Fade>
              </div>
            </div>
          </div>
          <div className="innovation-solutions-second-container">
            <p className="innovation-solutions-main-description">
              Our comprehensive analytics platform unlocks the power of your
              data, regardless of its source. Gain insights through intuitive
              data exploration tools, visually compelling dashboards, and
              advanced predictive analytics.
            </p>
          </div>
          {/* <div className="col-12 ">
            <p className="p-4 pl-3 mt-3 analytics-content">
              Our comprehensive analytics platform unlocks the power of your
              data, regardless of its source. Gain insights through intuitive
              data exploration tools, visually compelling dashboards, and
              advanced predictive analytics.
            </p>
            <h2>Key Features</h2>
            <ul className="AnalyticsList  mt-3 p-5">
              <li className="mt-3 mb-3 ">
                User-friendly interface for self-service BI
              </li>
              <li className="mt-3 mb-3 ">
                Drag-and-drop visualizations for quick analysis
              </li>
              <li className="mt-3 mb-3 ">Prebuilt and customizable reports</li>
              <li className="mt-3 mb-3 ">
                Integration with Oracle systems and other data sources
              </li>
            </ul> 
          </div> */}
          {/* <div className="col-12 col-lg-6 p-5 d-flex justify-content-center">
            <Fade direction="down">
              <div className="AnalyticsCardsContainer shadow">
                <h1
                  className="p-3 text-center  mb-0 AnalyticsEnquiryHeading"
                  style={{
                    marginBottom: "0px",
                    color: "#ffffff",

                    fontWeight: "bold",
                    background: "#4353ff",
                  }}
                >
                  Enquire Now
                </h1>
                <form className="form-control p-3">
                  <p classname="mb-2 mt-2 text-left p-2">
                    Please fill in your information and we'll get in touch with
                    you.
                  </p>
                  <label htmlFor="firstName">
                    First Name <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    style={{ border: "1px solid" }}
                    required
                    type="text"
                    id="firstName"
                    className="w-100 mt-3 p-2 mb-3"
                  />
                  <label htmlFor="lastName">
                    Last Name <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    style={{ border: "1px solid" }}
                    required
                    type="text"
                    id="lastName"
                    className="w-100 mt-3  p-2 mb-3"
                  />
                  <label htmlFor="email">
                    Email<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    style={{ border: "1px solid" }}
                    required
                    type="text"
                    id="email"
                    className="w-100 mt-3 p-2 mb-3"
                  />
                  <label htmlFor="phoneNumber">
                    PhoneNumber <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    style={{ border: "1px solid" }}
                    required
                    type="text"
                    id="phoneNumber"
                    className="w-100 mt-3 p-2 mb-3"
                  />
                  <label htmlFor="CompanyName">
                    Company Name <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    style={{ border: "1px solid" }}
                    required
                    type="text"
                    id="CompanyName"
                    className="w-100 mt-3 p-2 mb-3"
                  />

                  <label htmlFor="jobTitle">
                    Job Title <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    style={{ border: "1px solid" }}
                    required
                    type="text"
                    id="jobTitle"
                    className="w-100 mt-3 p-2 mb-3"
                  />
                  <label htmlFor="Country/Region">
                    Country/Region <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    style={{ border: "1px solid" }}
                    required
                    type="text"
                    id="Country/Region"
                    className="w-100 mt-3 p-2 mb-3"
                  />
                  <label htmlFor="message">
                    Message <span style={{ color: "red" }}>*</span>
                  </label>
                  <textarea
                    style={{ border: "1px solid" }}
                    required
                    rows="5"
                    id="message"
                    className="mb-3 w-100"
                  ></textarea>
                  <button
                    className="p-3"
                    style={{
                      height: "45px",
                      width: "100px",
                      border: "none",
                      background: "#0f4494",
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#ffffff",
                      borderRadius: "8px",
                    }}
                  >
                    Submit
                  </button>
                </form>
              </div>
            </Fade>
          </div> */}
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="text-center p-2" style={{ color: "white" }}>
              Key Solutions for your Advance Analytics and Business Intelligence
            </h1>
            <p className="p-2 text-center" style={{ color: "white" }}>
              Integrate our credible knowledge with the most robust BI and smart
              analytics tools in the world to make insightful, data-driven
              decisions and elevate your business.
            </p>
          </div>

          {cards.map((each) => (
            <div className="col-12 col-md-6 mb-3">
              <Fade direction="up">
                <div className="cardsContainer  p-5">
                  <img
                    className="mt-3"
                    src={each.imageUrl}
                    alt={each.title}
                    height="150"
                    width="150"
                  />
                  <h5
                    className="text-primary text-center  mb-0 mt-2"
                    style={{ color: "white" }}
                  >
                    {each.title}
                  </h5>
                  <p
                    className="mb-3 p-4 text-center"
                    style={{ color: "white" }}
                  >
                    {each.description}
                  </p>
                </div>
              </Fade>
            </div>
          ))}

          <div className="col-12 col-md-6 text-center mb-3">
            <Fade direction="up">
              <img src={Image3} alt="Image3" height="300" width="300" />
            </Fade>
          </div>

          <div className="col-12 col-md-6 AnalyticsListContainer mb-3">
            <Fade direction="up">
              <h1 style={{ color: "white" }}>
                Fusion CX/ERP/HCM/SCM Analytics
              </h1>
              <ul className="AnalyticsList text-center mt-3  p-2 pl-3">
                <li className="mt-3 mb-3 " style={{ color: "#aaaaaa" }}>
                  Enhance customer experiences, optimize sales and marketing,
                  and drive service excellence.
                </li>
                <li className="mt-3 mb-3 " style={{ color: "#aaaaaa" }}>
                  Improve financial visibility, streamline decision-making, and
                  identify cost-saving opportunities.
                </li>
                <li className="mt-3 mb-3 " style={{ color: "#aaaaaa" }}>
                  Analyze workforce trends, manage talent effectively, and make
                  data-driven HR decisions
                </li>
                <li className="mt-3 mb-3 " style={{ color: "#aaaaaa" }}>
                  Optimize supply chain processes, improve inventory management,
                  and enhance demand forecasting.
                </li>
              </ul>
            </Fade>
          </div>
          <div className="col-12 col-md-6 AnalyticsListContainer mb-3">
            <Fade direction="up">
              <h1 style={{ color: "white" }}>Why Partner with Omnifinity?</h1>
              <ul className="AnalyticsList text-center mt-3 p-2">
                <li className="mt-3 mb-3 " style={{ color: "#aaaaaa" }}>
                  Our certified team guides you through every stage of
                  implementation and ensures you get the most from your
                  investment.
                </li>
                <li className="mt-3 mb-3" style={{ color: "#aaaaaa" }}>
                  Leverage prebuilt solutions to start driving insights quickly
                  and see tangible results sooner.
                </li>
                <li className="mt-3 mb-3" style={{ color: "#aaaaaa" }}>
                  We'll help you turn your data into a competitive advantage,
                  fueling better decision-making across your organization.
                </li>
              </ul>
            </Fade>
          </div>
          <div className="col-12 col-md-6 text-center mb-3">
            <Fade direction="up">
              <img src={Image4} alt="Image3" height="300" width="300" />
            </Fade>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="text-center mb-3 mt-3" style={{ color: "white" }}>
                Omnifinity for your Advance Analytics and BI
              </h1>
            </div>
            <div className="col-12  mt-3 mb-3 text-center col-md-3">
              <button
                className={
                  activeTab === "Consult"
                    ? "omnifinity-button-active"
                    : "omnifinity-button-inactive"
                }
                onClick={() => setTab("Consult")}
              >
                Consult
              </button>
            </div>
            <div className="col-12   mt-3 mb-3 text-center col-md-3">
              <button
                className={
                  activeTab === "Implement"
                    ? "omnifinity-button-active"
                    : "omnifinity-button-inactive"
                }
                onClick={() => setTab("Implement")}
              >
                Implement
              </button>
            </div>
            <div className="col-12  mt-3 mb-3 text-center col-md-3">
              <button
                className={
                  activeTab === "Manage"
                    ? "omnifinity-button-active"
                    : "omnifinity-button-inactive"
                }
                onClick={() => setTab("Manage")}
              >
                Manage
              </button>
            </div>
            <div className="col-12  mt-3 mb-3 text-center col-md-3">
              <button
                className={
                  activeTab === "Improve"
                    ? "omnifinity-button-active"
                    : "omnifinity-button-inactive"
                }
                onClick={() => setTab("Improve")}
              >
                Improve
              </button>
            </div>
            <div className="col-12 col-xl-6  mt-3 mb-3">
              <h4 className="mt-3 mb-3 p-2" style={{ color: "white" }}>
                {Filtered[0].title1}
              </h4>
              <p className="mt-3 mb-3 p-2" style={{ color: "#aaaaaa" }}>
                {Filtered[0].description1}
              </p>
              <h4 className="mt-3 mb-3 p-2" style={{ color: "white" }}>
                {Filtered[0].title2}
              </h4>
              <p className="mt-3 mb-3 p-2" style={{ color: "#aaaaaa" }}>
                {Filtered[0].description2}
              </p>
              <h4 className="mt-3 mb-3 p-2" style={{ color: "white" }}>
                {Filtered[0].title3}
              </h4>
              <p className="mt-3 mb-3 p-2" style={{ color: "#aaaaaa" }}>
                {Filtered[0].description3}
              </p>
              <h4 className="mt-3 mb-3 p-2" style={{ color: "white" }}>
                {Filtered[0].title4}
              </h4>
              <p className="mt-3 mb-3 p-2" style={{ color: "#aaaaaa" }}>
                {Filtered[0].description4}
              </p>
            </div>
            <div
              className="col-12 col-xl-6 mt-3 mb-3"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={Filtered[0].imageUrl} width="600" height="447" alt="" />
            </div>
            <div className="col-12">
              <hr className="w-100" />
            </div>
            <div className="col-12 text-center">
              <p style={{ fontSize: "18px", color: "white" }}>
                Omnifinity can help you master the art of utilizing real-time
                data streams. Solve business-critical challenges, enhance the
                customer experience, and meet your organizational goals. With
                35+ years of industry expertise and dedicated GCP-certified
                specialists, we promise impactful, scalable, and quantifiable
                results.
              </p>
              <div className="text-center d-flex justify-content-center mt-3 w-100  mb-4">
                <button className="omnifinity-button-inactive">
                  Contact Us
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
